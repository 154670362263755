@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("icon");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 .#{$component} {
  color: $zaux-color-set1-blue900;
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.zaux-theme--dark1 .#{$component} {
  color: $zaux-color-default-white;
}
