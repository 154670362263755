@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("slider");

// /swiper/modules/pagination/pagination.scss

:root {
  // --swiper-theme-color: #{tokenColor("set1", "cyan1")};
  // --swiper-pagination-bullet-inactive-color: #F00;
  --swiper-pagination-bullet-horizontal-gap: 2rem;
  --swiper-pagination-bullet-vertical-gap: 0;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
}

.#{$component} {
  &__wrapper {
    overflow: hidden;
    position: relative;
  }

  &--fx-1 {
    .swiper-wrapper {
      // display: flex;
      transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
    }
  }

  &--var1 {
    a {
      height: 80px;
      pointer-events: none;
    }
  }

  &--var2 {
    a {
      pointer-events: none;
    }

    img {
      max-height: 160px;
    }
  }
}
