@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("fx");

[class*="#{$component}--overlay"] {
  &:after {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/mix-blend-mode
    // mix-blend-mode: multiply;

    position: absolute;
    top: 0;
    left: 0;

    content: " ";
    width: 100%;
    height: 100%;
    display: block;

    z-index: $zaux-zindex-contents - 2;
  }
}

.#{$component} {
  &--overlay1 {
    &:after {
      background-color: rgba(#000, 0.3);
    }
  }
  &--bs1 {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
  }
}
