@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("typo");

////////////////////////////////////////////////////
// Font-family (ff)
////////////////////////////////////////////////////

.#{$component} {
  &-ff-1 {
    font-family: $zaux-font1 !important;
  }

  &-ff-2 {
    font-family: $zaux-font2 !important;
  }

  &-ff-3 {
    font-family: $zaux-font3 !important;
  }

  &-ff-4 {
    font-family: $zaux-font-system !important;
  }
}

////////////////////////////////////////////////////
// Font-weight (fw)
////////////////////////////////////////////////////

.#{$component} {
  &-fw-regular {
    font-weight: $zaux-typo-fw-regular !important;
  }

  &-fw-medium {
    font-weight: $zaux-typo-fw-medium !important;
  }

  &-fw-bold {
    font-weight: $zaux-typo-fw-bold !important;
  }
}

////////////////////////////////////////////////////
// Font-style (style)
////////////////////////////////////////////////////

////////////////////////////////////////////////////
// Base font-size (fs)
////////////////////////////////////////////////////

@include c-typo-text-rules("fs", "", true);
@include c-typo-text-rules-responsive("fs", "", true);

////////////////////////////////////////////////////
// Typography Block: Texts
////////////////////////////////////////////////////

// @include c-typo-text-rules("texts", 'p:not([class*="c-typo-fs-"])');
// @include c-typo-text-rules("texts", 'li:not([class*="c-typo-fs-"])');

// @include c-typo-text-rules-responsive("texts", 'p:not([class*="c-typo-fs-"])');
// @include c-typo-text-rules-responsive("texts", 'li:not([class*="c-typo-fs-"])');

[class*="#{$component}-texts-"] {
  p,
  li {
    font-weight: $zaux-typo-fw-regular;
  }

  strong {
    font-weight: $zaux-typo-fw-medium;
    font-family: $zaux-font2;
  }

  li {
    margin-bottom: getSpace("3"); // ~16px
  }

  p,
  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: getSpace("4"); // ~24px
  }

  ul,
  ol {
    padding-left: getSpace("3"); // ~24px
  }

  ul ul:first-child,
  ol ol:first-child {
    padding-top: getSpace("2"); // ~8px
  }
}

////////////////////////////////////////////////////
// Letter-spacing (ls)
////////////////////////////////////////////////////

.#{$component} {
  &-ls-nf1 {
    letter-spacing: -1px;
  }
  &-ls-f5 {
    letter-spacing: 5px;
  }
}

////////////////////////////////////////////////////
// Text-shadow (ts)
////////////////////////////////////////////////////

.#{$component} {
  &-ts-1 {
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
  }
}

////////////////////////////////////////////////////
// Line-height (lh)
////////////////////////////////////////////////////

.#{$component} {
  &-lh-0 {
    line-height: 0 !important;
  }

  &-lh-1 {
    line-height: 1 !important;
  }
}
