@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("footer");

.#{$component} {
  #navFooter {
    li {
      margin-bottom: 1rem;
    }
  }
}
