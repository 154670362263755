@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");

.#{$component} {
  position: fixed;
  left: 0;
  top: 0;

  z-index: $zaux-zindex-header;
  transition: all $zaux-transition-1;

  width: 100%;

  .navbar {
    @include media-breakpoint-up(lg) {
      padding-top: 27px;
    }
  }

  &__logo {
    span {
      min-width: 130px;
    }
  }

  &__inner {
    transition: height $zaux-transition-1;
    background-color: $zaux-color-default-white;

    height: $zaux-header-h;

    @include media-breakpoint-up(lg) {
      height: $zaux-header-lg-h;
    }

    nav {
      @include media-breakpoint-up(lg) {
        ul {
          width: 100%;
          display: flex;
          justify-content: center;

          li {
            margin: 0 10px;
          }
        }
      }
    }
  }

  .c-nav1 a {
    color: $zaux-color-set1-grey300;
  }
}

.c-header__logo {
  @include media-breakpoint-down(lg) {
    img {
      max-width: 89px !important;
    }
  }
}
