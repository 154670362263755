@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("typo");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 {
  a {
    &:link,
    &:visited {
      color: $zaux-color-set1-blue900;
    }

    &:hover,
    &:active {
      color: $zaux-color-set1-blue900;
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h6,
  label,
  p {
    color: $zaux-color-set1-blue900;
  }
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.zaux-theme--dark1 {
  a {
    &:link,
    &:visited {
      color: $zaux-color-set1-aqua200;
    }

    &:hover,
    &:active {
      color: $zaux-color-set1-aqua200;
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h6,
  label,
  p {
    color: $zaux-color-default-white;
  }
}
