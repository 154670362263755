// Fix SVG in <img> tags not scaling in IE9, IE10, IE11
// IE9, IE10, and IE11 don't properly scale SVG files added with img tags when viewBox, width
// and height attributes are specified. To get more consistent scaling across browsers always ensure
// you specify a viewBox but leave off the width and height attributes on your svg element.
// @see https://gist.github.com/larrybotha/7881691
// @see https://stackoverflow.com/questions/9777143/svg-in-img-element-proportions-not-respected-in-ie9/9792254#9792254
@include onIE() {
  img[src$=".svg"] {
    width: 100%;
  }
}

// Improving Font Rendering With CSS
// @see https://betterprogramming.pub/improving-font-rendering-with-css-3383fc358cbc
body {
  @include text-antialiased;
}

// Custom scrollbars
:root {
  @include scrollbars(7px, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0.5), 0);
}

//////////////////////////////////////////////////
// MISC
//////////////////////////////////////////////////
#zaux-content-inner {
  padding-top: $zaux-header-h;

  @include media-breakpoint-up(lg) {
    padding-top: $zaux-header-lg-h;
  }
}

.c-hero {
  position: relative;

  figure {
    // max-height: 854px;
    // height: 60vw;
    height: 654px;

    @include media-breakpoint-up(lg) {
      // height: 854px;
      height: 943px;
    }
  }

  &__box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
  }
}

.mw-4 {
  max-width: 624px;
}

.mw-5 {
  max-width: 980px;
}

.c-gmap {
  background-color: #eee;

  iframe {
    border: 0;
    width: 100%;
    height: 631px;
    display: block;
  }
}

.navbar-toggler {
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 34px;
    right: 20px;
    font-size: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 1;
    box-shadow: none !important;

    span {
      font-size: 32px;
      width: auto;
      height: auto;
      line-height: 0;
      display: block;
      // color: $zaux-color-set1-onyx;
      color: $zaux-color-default-white;
      transform: scaleX(1.2);
    }
  }
}

#navMain {
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: $zaux-header-h;
    right: 0px;

    z-index: 1;
    width: 100%;
    text-align: center;

    >div {
      background: $zaux-color-default-white;
      padding-top: 1.5rem;
      padding-bottom: 1rem;

      li {
        margin-bottom: 1rem;
      }
    }
  }
}

#timer1 {
  @include media-breakpoint-up(lg) {
    >div {
      min-width: 100px;
    }
  }
}

.c-btn1 {
  text-align: center;
  max-width: 280px;
  padding: 1rem;
  min-height: 57px;
  width: 100%;

  &,
  span {
    transition: all $zaux-transition-0;
  }
}

// .c-btn1--yellow {
//   &,
//   &:link,
//   &:visited {
//     background-color: $zaux-color-set1-yellow !important;
//     color: $zaux-color-set1-onyx !important;
//   }

//   &:hover,
//   &:active {
//     background-color: darken($zaux-color-set1-yellow, 15%) !important;
//     color: $zaux-color-default-white !important;
//     text-decoration: none !important;
//   }
// }

.c-btn1--aqua {

  &,
  &:link,
  &:visited {
    background-color: $zaux-color-set1-aqua200 !important;
    color: $zaux-color-default-white !important;
  }

  &:hover,
  &:active {
    background-color: darken($zaux-color-set1-aqua200, 15%) !important;
    color: $zaux-color-default-white !important;
    text-decoration: none !important;
  }
}

.deco1 {
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: 250px;
}

.deco2 {
  width: 100%;
  position: relative;

  height: 63px;
  top: -12px;

  @include media-breakpoint-up(lg) {
    height: 123px;
    top: -32px;
  }
}


.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



.fs-1 {
  font-size: 49px;
}

.fs-2 {
  font-size: 22px;
}

.fs-3 {
  font-size: 39px;
}

.fs-4 {
  font-size: 20px;
}

.fs-5 {
  font-size: 9px;
}

@include media-breakpoint-up(lg) {
  .fs-lg-1 {
    font-size: 49px;
  }
}

@include media-breakpoint-up(lg) {
  .fs-lg-2 {
    font-size: 22px;
  }
}

@include media-breakpoint-up(lg) {
  .fs-lg-3 {
    font-size: 39px;
  }
}

@include media-breakpoint-up(lg) {
  .fs-lg-4 {
    font-size: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .fs-lg-5 {
    font-size: 9px;
  }
}

.c-nav1 {
  @include media-breakpoint-down(lg) {
    border-top: 1px solid #dddddd;
  }
}



@include media-breakpoint-down(lg) {
  #hero {
    img {
      object-position: 73% center;
    }
  }
}


//////////////////////////////////////////////////
// Token: fontsize
//////////////////////////////////////////////////

$zaux-token-fs2-map: (
  0:0px,
  1:8px,
  2:10px,
  3:12px,
  4:14px,
  5:16px,
  6:18px,
  7:20px,
  8:22px,
  9:24px,
  10:28px,
  11:32px,
  12:36px,
  13:42px,
  14:52px,
  15:54px,
  16:60px,
  17:65px
);

$zaux-token-fs2-map-bp: (
  "sm",
  "md",
  "lg"
);

@mixin fs2-rules($val) {
  // font-size: rem($val * 1px);
  font-size: #{$val};
}

@each $i,
$val in $zaux-token-fs2-map {
  .fs2-#{$i} {
    @include fs2-rules($val);
  }
}

@each $j,
$mq in $zaux-token-fs2-map-bp {
  @include media-breakpoint-up($j) {

    @each $i,
    $val in $zaux-token-fs2-map {
      .fs2-#{$j}-#{$i} {
        @include fs2-rules($val);
      }
    }
  }
}


//////////////////////////////////////////////////
// Token: max-width
//////////////////////////////////////////////////

$zaux-token-mw2-map: (
  1 : 4px,
  2 : 8px,
  3 : 16px,
  4 : 32px,
  5 : 48px,
  6 : 64px,
  7 : 80px,
  8 : 96px,
  9 : 112px,
  10 : 128px,
  11 : 144px,
  12 : 160px,
  13 : 176px,
  14 : 192px,
  15 : 208px,
  16 : 224px,
  17 : 240px
);

$zaux-token-mw2-map-bp: (
  "sm",
  "md",
  "lg"
);

@mixin mw2-rules($val) {
  max-width: #{$val} !important;
}

@each $i,
$val in $zaux-token-mw2-map {
  .mw2-#{$i} {
    @include mw2-rules($val);
  }
}

@each $j,
$mq in $zaux-token-mw2-map-bp {
  @include media-breakpoint-up($j) {

    @each $i,
    $val in $zaux-token-mw2-map {
      .mw2-#{$j}-#{$i} {
        @include mw2-rules($val);
      }
    }
  }
}
