@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {
  @at-root .zaux-theme--light1 & {
    &__inner {
      background-color: $zaux-color-default-white;
    }
    &__search-btn {
      .c-icon {
        color: $zaux-color-set1-blue900;;
      }
    }
  }
}
