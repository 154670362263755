ul,
ol,
li,
dl,
dt,
dd,
address,
figure {
  padding: 0;
  margin: 0; }

button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0; }

[class^="icozaux1-"],
[class*=" icozaux1-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

:root {
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(255, 255, 255, 0.5) !important;
  scrollbar-width: thin !important; }
  :root::-webkit-scrollbar {
    width: 7px;
    height: 7px; }
  :root::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 0; }
  :root::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5); }
  :root {
    scrollbar-face-color: rgba(0, 0, 0, 0.25);
    scrollbar-track-color: rgba(255, 255, 255, 0.5); }

#zaux-content-inner {
  padding-top: 80px; }
  @media (min-width: 992px) {
    #zaux-content-inner {
      padding-top: 169px; } }

.c-hero {
  position: relative; }
  .c-hero figure {
    height: 654px; }
    @media (min-width: 992px) {
      .c-hero figure {
        height: 943px; } }
  .c-hero__box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20; }

.mw-4 {
  max-width: 624px; }

.mw-5 {
  max-width: 980px; }

.c-gmap {
  background-color: #eee; }
  .c-gmap iframe {
    border: 0;
    width: 100%;
    height: 631px;
    display: block; }

@media (max-width: 991.98px) {
  .navbar-toggler {
    position: fixed;
    top: 34px;
    right: 20px;
    font-size: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 1;
    box-shadow: none !important; }
    .navbar-toggler span {
      font-size: 32px;
      width: auto;
      height: auto;
      line-height: 0;
      display: block;
      color: #FFFFFF;
      transform: scaleX(1.2); } }

@media (max-width: 991.98px) {
  #navMain {
    position: fixed;
    top: 80px;
    right: 0px;
    z-index: 1;
    width: 100%;
    text-align: center; }
    #navMain > div {
      background: #FFFFFF;
      padding-top: 1.5rem;
      padding-bottom: 1rem; }
      #navMain > div li {
        margin-bottom: 1rem; } }

@media (min-width: 992px) {
  #timer1 > div {
    min-width: 100px; } }

.c-btn1 {
  text-align: center;
  max-width: 280px;
  padding: 1rem;
  min-height: 57px;
  width: 100%; }
  .c-btn1,
  .c-btn1 span {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }

.c-btn1--aqua, .c-btn1--aqua:link, .c-btn1--aqua:visited {
  background-color: #33C0B4 !important;
  color: #FFFFFF !important; }

.c-btn1--aqua:hover, .c-btn1--aqua:active {
  background-color: #23847b !important;
  color: #FFFFFF !important;
  text-decoration: none !important; }

.deco1 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px; }

.deco2 {
  width: 100%;
  position: relative;
  height: 63px;
  top: -12px; }
  @media (min-width: 992px) {
    .deco2 {
      height: 123px;
      top: -32px; } }

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em; }

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.fs-1 {
  font-size: 49px; }

.fs-2 {
  font-size: 22px; }

.fs-3 {
  font-size: 39px; }

.fs-4 {
  font-size: 20px; }

.fs-5 {
  font-size: 9px; }

@media (min-width: 992px) {
  .fs-lg-1 {
    font-size: 49px; } }

@media (min-width: 992px) {
  .fs-lg-2 {
    font-size: 22px; } }

@media (min-width: 992px) {
  .fs-lg-3 {
    font-size: 39px; } }

@media (min-width: 992px) {
  .fs-lg-4 {
    font-size: 20px; } }

@media (min-width: 992px) {
  .fs-lg-5 {
    font-size: 9px; } }

@media (max-width: 991.98px) {
  .c-nav1 {
    border-top: 1px solid #dddddd; } }

@media (max-width: 991.98px) {
  #hero img {
    object-position: 73% center; } }

.fs2-0 {
  font-size: 0px; }

.fs2-1 {
  font-size: 8px; }

.fs2-2 {
  font-size: 10px; }

.fs2-3 {
  font-size: 12px; }

.fs2-4 {
  font-size: 14px; }

.fs2-5 {
  font-size: 16px; }

.fs2-6 {
  font-size: 18px; }

.fs2-7 {
  font-size: 20px; }

.fs2-8 {
  font-size: 22px; }

.fs2-9 {
  font-size: 24px; }

.fs2-10 {
  font-size: 28px; }

.fs2-11 {
  font-size: 32px; }

.fs2-12 {
  font-size: 36px; }

.fs2-13 {
  font-size: 42px; }

.fs2-14 {
  font-size: 52px; }

.fs2-15 {
  font-size: 54px; }

.fs2-16 {
  font-size: 60px; }

.fs2-17 {
  font-size: 65px; }

@media (min-width: 576px) {
  .fs2-sm-0 {
    font-size: 0px; }
  .fs2-sm-1 {
    font-size: 8px; }
  .fs2-sm-2 {
    font-size: 10px; }
  .fs2-sm-3 {
    font-size: 12px; }
  .fs2-sm-4 {
    font-size: 14px; }
  .fs2-sm-5 {
    font-size: 16px; }
  .fs2-sm-6 {
    font-size: 18px; }
  .fs2-sm-7 {
    font-size: 20px; }
  .fs2-sm-8 {
    font-size: 22px; }
  .fs2-sm-9 {
    font-size: 24px; }
  .fs2-sm-10 {
    font-size: 28px; }
  .fs2-sm-11 {
    font-size: 32px; }
  .fs2-sm-12 {
    font-size: 36px; }
  .fs2-sm-13 {
    font-size: 42px; }
  .fs2-sm-14 {
    font-size: 52px; }
  .fs2-sm-15 {
    font-size: 54px; }
  .fs2-sm-16 {
    font-size: 60px; }
  .fs2-sm-17 {
    font-size: 65px; } }

@media (min-width: 768px) {
  .fs2-md-0 {
    font-size: 0px; }
  .fs2-md-1 {
    font-size: 8px; }
  .fs2-md-2 {
    font-size: 10px; }
  .fs2-md-3 {
    font-size: 12px; }
  .fs2-md-4 {
    font-size: 14px; }
  .fs2-md-5 {
    font-size: 16px; }
  .fs2-md-6 {
    font-size: 18px; }
  .fs2-md-7 {
    font-size: 20px; }
  .fs2-md-8 {
    font-size: 22px; }
  .fs2-md-9 {
    font-size: 24px; }
  .fs2-md-10 {
    font-size: 28px; }
  .fs2-md-11 {
    font-size: 32px; }
  .fs2-md-12 {
    font-size: 36px; }
  .fs2-md-13 {
    font-size: 42px; }
  .fs2-md-14 {
    font-size: 52px; }
  .fs2-md-15 {
    font-size: 54px; }
  .fs2-md-16 {
    font-size: 60px; }
  .fs2-md-17 {
    font-size: 65px; } }

@media (min-width: 992px) {
  .fs2-lg-0 {
    font-size: 0px; }
  .fs2-lg-1 {
    font-size: 8px; }
  .fs2-lg-2 {
    font-size: 10px; }
  .fs2-lg-3 {
    font-size: 12px; }
  .fs2-lg-4 {
    font-size: 14px; }
  .fs2-lg-5 {
    font-size: 16px; }
  .fs2-lg-6 {
    font-size: 18px; }
  .fs2-lg-7 {
    font-size: 20px; }
  .fs2-lg-8 {
    font-size: 22px; }
  .fs2-lg-9 {
    font-size: 24px; }
  .fs2-lg-10 {
    font-size: 28px; }
  .fs2-lg-11 {
    font-size: 32px; }
  .fs2-lg-12 {
    font-size: 36px; }
  .fs2-lg-13 {
    font-size: 42px; }
  .fs2-lg-14 {
    font-size: 52px; }
  .fs2-lg-15 {
    font-size: 54px; }
  .fs2-lg-16 {
    font-size: 60px; }
  .fs2-lg-17 {
    font-size: 65px; } }

.mw2-1 {
  max-width: 4px !important; }

.mw2-2 {
  max-width: 8px !important; }

.mw2-3 {
  max-width: 16px !important; }

.mw2-4 {
  max-width: 32px !important; }

.mw2-5 {
  max-width: 48px !important; }

.mw2-6 {
  max-width: 64px !important; }

.mw2-7 {
  max-width: 80px !important; }

.mw2-8 {
  max-width: 96px !important; }

.mw2-9 {
  max-width: 112px !important; }

.mw2-10 {
  max-width: 128px !important; }

.mw2-11 {
  max-width: 144px !important; }

.mw2-12 {
  max-width: 160px !important; }

.mw2-13 {
  max-width: 176px !important; }

.mw2-14 {
  max-width: 192px !important; }

.mw2-15 {
  max-width: 208px !important; }

.mw2-16 {
  max-width: 224px !important; }

.mw2-17 {
  max-width: 240px !important; }

@media (min-width: 576px) {
  .mw2-sm-1 {
    max-width: 4px !important; }
  .mw2-sm-2 {
    max-width: 8px !important; }
  .mw2-sm-3 {
    max-width: 16px !important; }
  .mw2-sm-4 {
    max-width: 32px !important; }
  .mw2-sm-5 {
    max-width: 48px !important; }
  .mw2-sm-6 {
    max-width: 64px !important; }
  .mw2-sm-7 {
    max-width: 80px !important; }
  .mw2-sm-8 {
    max-width: 96px !important; }
  .mw2-sm-9 {
    max-width: 112px !important; }
  .mw2-sm-10 {
    max-width: 128px !important; }
  .mw2-sm-11 {
    max-width: 144px !important; }
  .mw2-sm-12 {
    max-width: 160px !important; }
  .mw2-sm-13 {
    max-width: 176px !important; }
  .mw2-sm-14 {
    max-width: 192px !important; }
  .mw2-sm-15 {
    max-width: 208px !important; }
  .mw2-sm-16 {
    max-width: 224px !important; }
  .mw2-sm-17 {
    max-width: 240px !important; } }

@media (min-width: 768px) {
  .mw2-md-1 {
    max-width: 4px !important; }
  .mw2-md-2 {
    max-width: 8px !important; }
  .mw2-md-3 {
    max-width: 16px !important; }
  .mw2-md-4 {
    max-width: 32px !important; }
  .mw2-md-5 {
    max-width: 48px !important; }
  .mw2-md-6 {
    max-width: 64px !important; }
  .mw2-md-7 {
    max-width: 80px !important; }
  .mw2-md-8 {
    max-width: 96px !important; }
  .mw2-md-9 {
    max-width: 112px !important; }
  .mw2-md-10 {
    max-width: 128px !important; }
  .mw2-md-11 {
    max-width: 144px !important; }
  .mw2-md-12 {
    max-width: 160px !important; }
  .mw2-md-13 {
    max-width: 176px !important; }
  .mw2-md-14 {
    max-width: 192px !important; }
  .mw2-md-15 {
    max-width: 208px !important; }
  .mw2-md-16 {
    max-width: 224px !important; }
  .mw2-md-17 {
    max-width: 240px !important; } }

@media (min-width: 992px) {
  .mw2-lg-1 {
    max-width: 4px !important; }
  .mw2-lg-2 {
    max-width: 8px !important; }
  .mw2-lg-3 {
    max-width: 16px !important; }
  .mw2-lg-4 {
    max-width: 32px !important; }
  .mw2-lg-5 {
    max-width: 48px !important; }
  .mw2-lg-6 {
    max-width: 64px !important; }
  .mw2-lg-7 {
    max-width: 80px !important; }
  .mw2-lg-8 {
    max-width: 96px !important; }
  .mw2-lg-9 {
    max-width: 112px !important; }
  .mw2-lg-10 {
    max-width: 128px !important; }
  .mw2-lg-11 {
    max-width: 144px !important; }
  .mw2-lg-12 {
    max-width: 160px !important; }
  .mw2-lg-13 {
    max-width: 176px !important; }
  .mw2-lg-14 {
    max-width: 192px !important; }
  .mw2-lg-15 {
    max-width: 208px !important; }
  .mw2-lg-16 {
    max-width: 224px !important; }
  .mw2-lg-17 {
    max-width: 240px !important; } }

.zaux-fit-contain-center-center {
  object-fit: contain;
  object-position: center center;
  font-family: "object-fit: contain; object-position: center center;"; }

.zaux-fit-cover-center-center {
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;"; }

.zaux-color-default-white {
  color: #FFFFFF !important; }

.zaux-color-default-black {
  color: #333333 !important; }

.zaux-color-default-red {
  color: #d9534f !important; }

.zaux-color-default-green {
  color: #5cb85c !important; }

.zaux-color-default-blue {
  color: #337ab7 !important; }

.zaux-color-default-magenta {
  color: #FF00FF !important; }

.zaux-color-default-orange {
  color: #f0ad4e !important; }

.zaux-color-set1-blue900 {
  color: #2C3235 !important; }

.zaux-color-set1-blue700 {
  color: #1E3444 !important; }

.zaux-color-set1-aqua500 {
  color: #468A7A !important; }

.zaux-color-set1-aqua300 {
  color: #A2CECC !important; }

.zaux-color-set1-aqua200 {
  color: #33C0B4 !important; }

.zaux-color-set1-grey300 {
  color: #707070 !important; }

.zaux-color-set1-grey500 {
  color: #61666A !important; }

.zaux-bg-default-white {
  background: #FFFFFF !important; }

.zaux-bg-default-black {
  background: #333333 !important; }

.zaux-bg-default-red {
  background: #d9534f !important; }

.zaux-bg-default-green {
  background: #5cb85c !important; }

.zaux-bg-default-blue {
  background: #337ab7 !important; }

.zaux-bg-default-magenta {
  background: #FF00FF !important; }

.zaux-bg-default-orange {
  background: #f0ad4e !important; }

.zaux-bg-set1-blue900 {
  background: #2C3235 !important; }

.zaux-bg-set1-blue700 {
  background: #1E3444 !important; }

.zaux-bg-set1-aqua500 {
  background: #468A7A !important; }

.zaux-bg-set1-aqua300 {
  background: #A2CECC !important; }

.zaux-bg-set1-aqua200 {
  background: #33C0B4 !important; }

.zaux-bg-set1-grey300 {
  background: #707070 !important; }

.zaux-bg-set1-grey500 {
  background: #61666A !important; }

.zaux-svg-fluid-wrapper {
  display: block;
  line-height: 0; }
  .zaux-svg-fluid-wrapper svg {
    max-width: 100%; }

:root:not(.ie-legacy) .zaux-svg-fluid-wrapper svg {
  height: auto; }

[class*="c-ratio"]:before {
  display: block;
  content: " ";
  width: 100%; }

.c-ratio--1-1:before {
  padding-top: calc(1 / 1 * 100%); }

.c-ratio--38-25:before {
  padding-top: calc(25 / 38 * 100%); }

.c-ratio--16-9:before {
  padding-top: 56.25%; }

[class*="c-fx--overlay"]:after {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
  z-index: 18; }

.c-fx--overlay1:after {
  background-color: rgba(0, 0, 0, 0.3); }

.c-fx--bs1 {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4); }

.c-icon {
  display: inline-block;
  line-height: 0; }
  .c-icon, .c-icon[class*="-fs"] {
    line-height: 0 !important; }

.c-typo-ff-1 {
  font-family: "Montserrat", Helvetica Neue, Arial, sans-serif !important; }

.c-typo-ff-2 {
  font-family: "Montserrat", Helvetica Neue, Arial, sans-serif !important; }

.c-typo-ff-3 {
  font-family: "Montserrat", Helvetica Neue, Arial, sans-serif !important; }

.c-typo-ff-4 {
  font-family: "Helvetica Neue", Arial, sans-serif !important; }

.c-typo-fw-regular {
  font-weight: 400 !important; }

.c-typo-fw-medium {
  font-weight: 500 !important; }

.c-typo-fw-bold {
  font-weight: 700 !important; }

.c-typo-fs-2 {
  font-size: 0.75rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-3 {
  font-size: 0.875rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-5 {
  font-size: 1rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-6 {
  font-size: 1.125rem !important;
  line-height: 1.35 !important; }

.c-typo-fs-8 {
  font-size: 1.25rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-10 {
  font-size: 1.625rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-11 {
  font-size: 2.0625rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-12 {
  font-size: 2.375rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-13 {
  font-size: 2.75rem !important;
  line-height: 1.2 !important; }

.c-typo-fs-19 {
  font-size: 6rem !important;
  line-height: 1.2 !important; }

@media (min-width: 992px) {
  .c-typo-fs-lg-2 {
    font-size: 0.75rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-2 {
    font-size: 0.75rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-3 {
    font-size: 0.875rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-3 {
    font-size: 0.875rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-5 {
    font-size: 1rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-5 {
    font-size: 1rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-6 {
    font-size: 1.125rem !important;
    line-height: 1.35 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-6 {
    font-size: 1.125rem !important;
    line-height: 1.35 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-8 {
    font-size: 1.25rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-8 {
    font-size: 1.25rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-10 {
    font-size: 1.625rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-10 {
    font-size: 1.625rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-11 {
    font-size: 2.0625rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-11 {
    font-size: 2.0625rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-12 {
    font-size: 2.375rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-12 {
    font-size: 2.375rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-13 {
    font-size: 2.75rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-13 {
    font-size: 2.75rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-19 {
    font-size: 6rem !important;
    line-height: 1.2 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-19 {
    font-size: 6rem !important;
    line-height: 1.2 !important; } }

[class*="c-typo-texts-"] p,
[class*="c-typo-texts-"] li {
  font-weight: 400; }

[class*="c-typo-texts-"] strong {
  font-weight: 500;
  font-family: "Montserrat", Helvetica Neue, Arial, sans-serif; }

[class*="c-typo-texts-"] li {
  margin-bottom: 1rem; }

[class*="c-typo-texts-"] p,
[class*="c-typo-texts-"] ul:not(:last-child),
[class*="c-typo-texts-"] ol:not(:last-child) {
  margin-bottom: 1.5rem; }

[class*="c-typo-texts-"] ul,
[class*="c-typo-texts-"] ol {
  padding-left: 1rem; }

[class*="c-typo-texts-"] ul ul:first-child,
[class*="c-typo-texts-"] ol ol:first-child {
  padding-top: 0.5rem; }

.c-typo-ls-nf1 {
  letter-spacing: -1px; }

.c-typo-ls-f5 {
  letter-spacing: 5px; }

.c-typo-ts-1 {
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4); }

.c-typo-lh-0 {
  line-height: 0 !important; }

.c-typo-lh-1 {
  line-height: 1 !important; }

.c-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%; }
  @media (min-width: 992px) {
    .c-header .navbar {
      padding-top: 27px; } }
  .c-header__logo span {
    min-width: 130px; }
  .c-header__inner {
    transition: height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    background-color: #FFFFFF;
    height: 80px; }
    @media (min-width: 992px) {
      .c-header__inner {
        height: 169px; } }
    @media (min-width: 992px) {
      .c-header__inner nav ul {
        width: 100%;
        display: flex;
        justify-content: center; }
        .c-header__inner nav ul li {
          margin: 0 10px; } }
  .c-header .c-nav1 a {
    color: #707070; }

@media (max-width: 991.98px) {
  .c-header__logo img {
    max-width: 89px !important; } }

.c-footer #navFooter li {
  margin-bottom: 1rem; }

.c-wrapper--w1 {
  max-width: 1532px;
  padding-right: 1rem;
  padding-left: 1rem; }

[class*="c-wrapper--"] {
  margin-right: auto;
  margin-left: auto; }

:root {
  --swiper-pagination-bullet-horizontal-gap: 2rem;
  --swiper-pagination-bullet-vertical-gap: 0;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1; }

.c-slider__wrapper {
  overflow: hidden;
  position: relative; }

.c-slider--fx-1 .swiper-wrapper {
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important; }

.c-slider--var1 a {
  height: 80px;
  pointer-events: none; }

.c-slider--var2 a {
  pointer-events: none; }

.c-slider--var2 img {
  max-height: 160px; }

.c-img {
  line-height: 0;
  display: block; }
  .c-img img {
    opacity: 1;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-img:not(.c-img--lazy).c-img--fluid img {
    max-width: 100%;
    height: auto; }
  .c-img--lazy.c-img--fluid.-is-loaded img {
    max-width: 100%;
    height: auto; }
  .c-img--lazy:not([class*="-is-loaded"]) img {
    opacity: 0; }

.zaux-theme--light1 a:link, .zaux-theme--light1 a:visited {
  color: #2C3235; }

.zaux-theme--light1 a:hover, .zaux-theme--light1 a:active {
  color: #2C3235;
  text-decoration: underline; }

.zaux-theme--light1 h1,
.zaux-theme--light1 h2,
.zaux-theme--light1 h3,
.zaux-theme--light1 h6,
.zaux-theme--light1 label,
.zaux-theme--light1 p {
  color: #2C3235; }

.zaux-theme--dark1 a:link, .zaux-theme--dark1 a:visited {
  color: #33C0B4; }

.zaux-theme--dark1 a:hover, .zaux-theme--dark1 a:active {
  color: #33C0B4;
  text-decoration: underline; }

.zaux-theme--dark1 h1,
.zaux-theme--dark1 h2,
.zaux-theme--dark1 h3,
.zaux-theme--dark1 h6,
.zaux-theme--dark1 label,
.zaux-theme--dark1 p {
  color: #FFFFFF; }

.zaux-theme--light1 .c-icon {
  color: #2C3235; }

.zaux-theme--dark1 .c-icon {
  color: #FFFFFF; }

.zaux-theme--light1 .c-header__inner {
  background-color: #FFFFFF; }

.zaux-theme--light1 .c-header__search-btn .c-icon {
  color: #2C3235; }
