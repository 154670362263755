@charset "UTF-8";

$zaux-helper-name: "fit";

.zaux-#{$zaux-helper-name} {
  &-contain {
    &-center-center {
      @include objectfit(contain, center center);
    }
    // &-left-center {
    //   @include objectfit(contain, left center);
    // }
    // &-right-center {
    //   @include objectfit(contain, right center);
    // }
  }
  &-cover {
    &-center-center {
      @include objectfit(cover, center center);
    }
    // &-left-center {
    //   @include objectfit(cover, left center);
    // }
    // &-right-center {
    //   @include objectfit(cover, right center);
    // }
  }
  // @include media-breakpoint-up(lg) {
  //   &-lg-cover-center-center {
  //     @include objectfit(cover, center center);
  //   }
  //   &-lg-cover-left-center {
  //     @include objectfit(cover, left center);
  //   }
  //   &-lg-cover-right-center {
  //     @include objectfit(cover, right center);
  //   }
  // }
}
